@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url('../src/assets/images/neon-cursor.png'), auto;
}

button {
  z-index: 1;
}

input {
  z-index: 2;
}

button:hover,
input:hover {
  cursor: url('../src/assets/images/neon-hand.png'), auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.game-clock {
  font-family: 'Digital-7 Mono', monospace;
  font-size: 2rem;
  color: #4dff4d;
  text-shadow: 0 0 10px #4dff4d, 0 0 20px #4dff4d, 0 0 40px #4dff4d,
    0 0 80px #4dff4d;
  padding: 14px;
  border-radius: 5px;
  background-color: #000000;
  display: inline-block;
  margin-top: -10px;
}

.game-clock-flex {
  font-family: 'Digital-7 Mono', monospace;
  font-size: 2rem;
  color: #4dff4d;
  text-shadow: 0 0 10px #4dff4d, 0 0 20px #4dff4d, 0 0 40px #4dff4d,
    0 0 80px #4dff4d;
  padding: 20px;
  border-radius: 5px;
  background-color: #000000;
  display: flex;
}
.game-clock-flex-secondary {
  font-family: 'Digital-7 Mono', monospace;
  font-size: 2rem;
  color: #9333e9;
  text-shadow: 0 0 8px #9333e9, 0 0 20px #9333e9, 0 0 40px #9333e9,
    0 0 80px #9333e9;
  padding: 20px;
  border-radius: 5px;
  background-color: #000000;
  display: flex;
}

/* Modal */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 10px;
  background-color: #111;
  text-align: center;
}
.modal-background-help {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-help {
  padding: 20px;
  border-radius: 10px;
  background-color: #111;
  text-align: center;
  position: relative;
  color: #fff;
  max-width: 600px;
  overflow-y: auto;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  background: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-sub-header {
  font-weight: bold;
  color: #4dff4d; 
  margin-top: 20px; 
}

.modal-text {
  text-align: left;
  margin-top: 10px;
}

.countdown-text {
  font-size: 3rem;
  color: #4dff4d;
  text-shadow: 0 0 10px #4dff4d, 0 0 20px #4dff4d, 0 0 40px #4dff4d,
    0 0 80px #4dff4d;
  font-family: 'Digital-7 Mono', monospace;
}

.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  animation: animate 25s linear infinite;
  bottom: -150px;
  overflow: hidden;
}

.circles li:before {
  content: '';
  position: absolute;
  top: -50px;
  left: -100px;
  right: -100px;
  bottom: -100px;
  background: linear-gradient(#9333e9, #2463eb);
  z-index: -1;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg) scale(1);
    opacity: 1;
    border-radius: 150px;
  }
  100% {
    transform: translateY(-1000px) rotate(720deg) scale(0.5);
    opacity: 0;
    border-radius: 150px;
  }
}
